import React from "react"
import { graphql } from "gatsby"

import Layout from "src/components/Layout"
import PageHero from "src/components/PageHero"
import PageFooter from "src/components/page/Footer"
import Content from "src/components/Content"

import "./assessmentTemplate.scss"

const AssessmentTemplate = ({data: {page: {frontmatter: {
  meta, hero, footer, content,
}}} }) => (
  <Layout meta={meta}>
    <div className="assessment-template">
      <PageHero {...hero} />
      <section className="assessment-detail">
        <div className="container">
          <div className="row assessment-overview">
            <div className="col-lg-8 offset-lg-2">
              <h3>{content.title}</h3>
              <p>{content.subtitle}</p>
            </div>
          </div>
          <div className="row assessment-when">
            <div className="col-lg-8 offset-lg-2">
              <h5>{content.when.title}</h5>
              <div className="row">
                <div className="col-md-6">
                  <ul>
                    {content.when.items.slice(0,2).map(problem => 
                      <li key={problem}>{problem}</li>
                    )}
                  </ul>
                </div>
                <div className="col-md-6">
                  <ul>
                    {content.when.items.slice(2,4).map(problem => 
                      <li key={problem}>{problem}</li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row assessment-outcomes">
            {content.outcomes.map(outcome => (
              <div className="col-xl-4">
                <div className="outcome-card">
                  <img src={outcome.icon} alt={outcome.alt} />
                  <p><strong>{outcome.header}</strong><br />{outcome.body}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="row assessment-process">
            <div className="col-lg-8 offset-lg-2">
              <ul>
                {content.process.map((step, index) => (
                  <li>
                    <strong>{step.header}</strong>
                    <br />
                    {step.body}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 offset-lg-2 assessment-artifacts">
              <h5>Artifacts</h5>
              <Content html={content.artifacts} />
            </div>
            <div className="col-lg-4 assessment-requirements">
              <h5>Required from you</h5>
              <ul>
                {content.requirements.map(requirement => (
                  <li>{requirement}</li>
                ))}
              </ul>
              <p><strong>Timing: </strong>{content.timing}</p>
            </div>
          </div>
        </div>
      </section>
    </div>

    <PageFooter {...footer} />
  </Layout>
)

export default AssessmentTemplate

export const pageQuery = graphql`
  query($path: String!) {
    page: markdownRemark(frontmatter: { meta: { path: { eq: $path } } }) {
      html
      ...MetaFragment
      ...HeroFragment
      ...FooterFragment
      frontmatter {
        content {
          title
          subtitle
          when {
            title
            items
          }
          outcomes {
            header
            body
            icon
          }
          process {
            header
            body
          }
          artifacts
          requirements
          timing
        }
      }
    }
  }
`
